<template>

    <div>
        <div class="row section-header" >
            <div class="col-md-3 section-title">
                <h3>Scuola</h3>
            </div>

            <div class="col-md-6">
                <p class="text-muted"><br class="hidden-xs">{{ currentSchool.name }} di {{ owner.firstname }} {{ owner.lastname }}</p>
            </div>

            <div class="col-md-3 text-right" style="">
            </div>
        </div>


       <br>

        <div class="content">
            <div class="index" :class="{ 'opened': (!edit) }">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-6">
                            <h3>Dati scuola</h3>
                            <dl class="">
                                <dt class="">Nome</dt>
                                <dd class="">{{ currentSchool.name }}</dd>

                                <dt class="">Ragione Sociale</dt>
                                <dd class="">{{ currentSchool.businessName }}</dd>

                                <dt class="">Indirizzo</dt>
                                <dd class="">{{ currentSchool.address }}</dd>

                                <dt class="">Partita IVA</dt>
                                <dd class="">{{ currentSchool.vat }}</dd>

                                <dt class="">Email</dt>
                                <dd class="">{{ currentSchool.email }}</dd>

                                <dt class="">Pec</dt>
                                <dd class="">{{ currentSchool.pec }}</dd>

                                <dt class="">Telefono</dt>
                                <dd class="">{{ currentSchool.telephone }}</dd>
                            </dl>

                        </div>
                        <div class="col-lg-6">
                            <h3>Proprietario</h3>
                            <dl class="">
                                <dt class="">Nome</dt>
                                <dd class="">{{ owner.firstname }} {{ owner.lastname }}</dd>

                                <dt class="">Email</dt>
                                <dd class="">{{ owner.email }}</dd>


                            </dl>

                            <h3>Programmi disponibili</h3>
                            <program-school-view :current-school="currentSchool"
                                                 v-on:closed-editor="closeEditor()" />
                        </div>
                    </div>
                    <hr>
                    <p><b-icon icon="pencil"/> Per aggiornare i dati invia una mail a <a href="mailto:info@toccafondimultimedia.com">info@toccafondimultimedia.com</a></p>
                </div>
            </div>
        </div>

        <br>
        <classroom-index />

        <br>

        <div v-if="currentSchool.id">
            <school-hour-price-index :school="currentSchool"/>
        </div>

    </div>

</template>

<script>
import SchoolDataService from "./SchoolDataService";
import ProgramSchoolView from "@/components/school/ProgramSchoolView";
import ClassroomIndex from "@/components/classroom/ClassroomIndex";
import SchoolHourPriceIndex from "@/components/school/SchoolHourPriceIndex";

export default {
    name: "school-view",
    components: {SchoolHourPriceIndex, ClassroomIndex, ProgramSchoolView},
    data() {
        return {
            currentSchool: {},
            owner: {},
            message: '',
            schoolId: -1,
            edit: false
        };
    },
    methods: {
        getSchool(id) {
            SchoolDataService.get(id)
                .then(response => {
                    this.currentSchool = response.data;
                    this.currentSchool.isMaster = (this.currentSchool.isMaster === 1);
                    this.owner = this.currentSchool.profiles[0];
                })
                .catch(e => {
                    console.log(e);
                });
        },
        getOwner(id) {

        },
        refreshCurrentSchool(school) {
            this.getSchool(this.schoolId)
        },
        closeEditor() {
            this.edit = false;
        }
    },
    mounted() {
        this.message = '';
        this.schoolId = this.$store.state.schoolId;
        this.getSchool(this.schoolId);
    }
};
</script>

