<template>
    <div>

        <div class="top-content ">
            <span class="h4">Prezzi ore guida</span>
            <button class="btn btn-primary btn-add" @click="editorOpened = true">
                Modifica i prezzi
            </button>
        </div>

        <div class="content">
            <div class="index">
                <div class="card">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Tipo</th>
                            <th scope="col">Prezzo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in prices"
                            :key="item.name"
                        >
                            <td>{{ item.name }}</td>
                            <td>{{ item.price | toCurrency }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="editor" :class="{ 'opened': (editorOpened) }">
                <div class="top-editor">
                    <b-button variant="link" @click="closeEditor"  :disabled="this.$store.state.loading"><b-icon icon="x"/> Chiudi</b-button>
                </div>
                <school-hour-price-edit :school-id="school.id" :key="school.id" v-if="editorOpened"
                                        v-on:updated-prices="refreshPrices($event)"
                                        v-on:closed-editor="closeEditor()"/>
            </div>

        </div>

    </div>

</template>

<script>
import SchoolHourPriceEdit from "@/components/school/SchoolHourPriceEdit";

export default {
    name: "school-hour-price-index",
    components: {SchoolHourPriceEdit},
    data() {
        return {
            prices: [],
            editorOpened: false
        };
    },
    props: ['school'],
    methods: {
        setPrices() {
            this.prices = [
                {
                    name: 'Guide con istruttore e veicolo',
                    price: this.school.practicePriceHour,
                },
                {
                    name: 'Guide al simulatore',
                    price: this.school.simulatorPriceHour,
                }
            ]
        },

        refreshPrices(prices) {
            this.prices[0].price = prices.practicePriceHour;
            this.prices[1].price = prices.simulatorPriceHour;
            this.closeEditor();
        },

        closeEditor() {
            this.editorOpened = false;
        }
    },
    mounted() {
        this.setPrices();
    }
};
</script>

<style scoped>
@media only screen and (min-width: 601px) {
    .top-content .btn-add {
        margin-left: 40px
    }
}

</style>
