<template>
    <div v-if="currentSchool">
        <table class="table table-striped">
            <tbody>
            <tr v-for="program in currentSchool.programs" :key="program.id">
                <td>{{ program.name }}</td>
            </tr>
            </tbody>
        </table>

    </div>

    <div v-else>
        <br/>
        <p>Carico...</p>
    </div>
</template>

<script>

export default {
    name: "program-school-view",
    props: ["currentSchool"]
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
