<template>
    <div v-if="prices">

        <div class="container">
            <h4>Prezzi ore guida</h4>
        </div>
        <div class="card">
            <b-form @submit="updatePrices">

                <b-row>
                    <b-col sm="6">
                        <b-form-group id="practicePriceHour" label="Prezzo ora guida reale" label-for="practicePriceHour" description="">
                            <!-- <b-form-input v-model="prices.practicePriceHour" placeholder=""></b-form-input> -->
                            <currency-input v-model="prices.practicePriceHour" :options="{ currency: 'EUR' }" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group id="simulatorPriceHour" label="Prezzo ora simulatore" label-for="simulatorPriceHour" description="">
                            <currency-input v-model="prices.simulatorPriceHour" :options="{ currency: 'EUR' }" />
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>

            <div class="action-bar row">
                <div class="col-sm-8">
                    <button class="btn btn-success " @click="updatePrices" :disabled="this.$store.state.loading">
                        <b-icon icon="file-earmark-check"/>
                        Salva
                    </button>
                </div>
            </div>
            <div>
                <br>
                <b-alert variant="success" :show="message != ''" fade>
                    <b-icon icon="hand-thumbs-up"/>
                    {{ message }}
                </b-alert>
                <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
            </div>
        </div>

    </div>

    <div v-else>
        <br/>
        <p>Carico...</p>
    </div>
</template>

<script>
import SchoolDataService from "./SchoolDataService";
import CurrencyInput from '../utility/CurrencyInput';

export default {
    name: "school-hour-price-edit",
    components: {CurrencyInput},
    data() {
        return {
            prices: null,
            message: '',
            errorMsg: ''
        };
    },
    props: ["schoolId"],
    methods: {
        getPrices(id) {
            SchoolDataService.get(id)
                .then(response => {
                    if (response.data) {
                        this.prices = {
                            practicePriceHour: Number(response.data.practicePriceHour),
                            simulatorPriceHour: Number(response.data.simulatorPriceHour)
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        updatePrices() {
            SchoolDataService.update(this.schoolId, this.prices)
                .then(response => {
                    this.$emit('updated-prices', this.prices);
                })
                .catch(e => {
                    this.errorMsg = e;
                    console.log(e);
                });
        },

        closeEditor() {
            this.$emit('closed-editor');
        },


    },
    mounted() {
        this.errorMsg = '';
        this.getPrices(this.schoolId);
    }
};
</script>

<style>
.action-bar {
    margin-top: 15px;
}
</style>
